import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';

const AccountRouter = () => {
    useEffect(() => {
        console.log('Page loaded');
    }, []);


    const theme = createTheme({
        palette: {
            mode: 'light',

            // background: {
            //     paper: 'linear-gradient(45deg,rgba(255,255,0,0.5),rgba(255,255,255,0.2)) ',
            // },
        },
        components: {
            // Style overrides for Paper component
            // MuiPaper: {
            //     styleOverrides: {
            //         root: {
            //             // Apply gradient background to all Paper components
            //             background: 'linear-gradient(135deg, rgba(255,255, 255, 0.2), rgba(255, 255, 255, 0.1))',
            //             backdropFilter: 'blur(8px)',
            //             borderTop: '1px solid rgba(255,255,255,0.2)',
            //             borderLeft: '1px solid rgba(255,255,255,0.2)',
            //             borderBottom: '1px solid rgba(0,0,0,0.4)',
            //             borderRight: '1px solid rgba(0,0,0,0.4)',
            //         },
            //     },
            // },

        },
    })

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Routes>
                    <Route exact path="/" element={<LoginPage />} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
};

export default AccountRouter;
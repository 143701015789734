import React, { useState } from 'react'
import DashboardComponent from '../components/DashboardComponent'
import DashboardIcon from '@mui/icons-material/Dashboard';


export default function HomePage() {

    const menuList = [
        { index: 1, text: 'Dashboard', icon: <DashboardIcon /> },
        { index: 2, text: 'Dashboard', icon: <DashboardIcon /> },
    ]

    const [selectedMenuIndex, setSelectedMenuIndex] = useState(1)

    return (
        <div>
            <DashboardComponent menuList={menuList} selectedMenuIndex={selectedMenuIndex} setSelectedMenuIndex={setSelectedMenuIndex} >
                {selectedMenuIndex === 1 && <div>Dashboard</div>}
                {selectedMenuIndex === 2 && <div>Dashboard 2</div>}

            </DashboardComponent>
        </div>
    )
}

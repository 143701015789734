import React from 'react';
import { Tabs, Tab, Paper, Box, useTheme, AppBar } from '@mui/material';
import Login from '../components/Login';
import Signup from '../components/Signup';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import SwipeableViews from 'react-swipeable-views';




export default function LoginPage() {
    const [selectedTab, setSelectedTab] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    const theme = useTheme();

    return (
        <Box sx={{ background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})`, width: '100%', height: '100vh' }}>
            <Grid2 container direction="column" justifyContent="center" alignItems="center" width={'100%'} >
                <Grid2 xs={12} md={8} lg={6} p={6} pt={30}>
                    < Paper elevation={24}>
                        <AppBar position="static" sx={{ borderRadius: 'inherit' }} >
                            <Tabs
                                value={selectedTab}
                                onChange={handleTabChange}
                                orientation="horizontal"
                                variant='fullWidth'
                                textColor="inherit"

                            >
                                <Tab label="Login" />
                                <Tab label="Signup" />
                            </Tabs>
                        </AppBar>
                        <Box sx={{ p: 3 }} >
                            <SwipeableViews index={selectedTab} onChangeIndex={(index) => setSelectedTab(index)}>
                                {selectedTab === 0 && <Login />}
                                {selectedTab === 1 && <Signup />}
                            </SwipeableViews>
                        </Box>
                    </Paper>
                </Grid2>
            </Grid2>
        </Box >
    );
}

// import React, { lazy, Suspense } from "react";

// const MainRouter = lazy(() => import("./Main/MainRouter"));
// const AdminRouter = lazy(() => import("./Admin/AdminRouter"));
// // const Academy = lazy(() => import("./Websites/Academy"));
// // const Clothing = lazy(() => import("./Websites/Clothing"));

// function DomainRouting() {
//   const host = window.location.hostname.split(".");
//   var subdomain = ''

//   if (host.length > 2) {
//     subdomain = host[0];
//   }

//   console.log(subdomain);

//   const renderComponent = (Component) => (
//     <Suspense fallback={<div>Loading...</div>}>
//       <Component />
//     </Suspense>
//   );

//   let componentToRender = MainRouter;

//   if (subdomain === "" || subdomain === "www") {
//     componentToRender = MainRouter;
//   } else if (subdomain === "admin") {
//     componentToRender = AdminRouter;
//   }

//   return (
//     <>
//       {renderComponent(componentToRender)}
//     </>
//   );
// }

// export default DomainRouting;


import React from "react";
import MainRouter from "./Main/MainRouter";
import AdminRouter from "./Admin/AdminRouter";
import AccountRouter from "./Account/AccountRouter";
// Uncomment and import these if needed
// import Academy from "./Websites/Academy";
// import Clothing from "./Websites/Clothing";

function DomainRouting() {
  const host = window.location.hostname.split(".");
  let subdomain = '';

  if (host.length > 2) {
    subdomain = host[0];
  }

  console.log(subdomain);

  // Directly determine which component to render based on subdomain
  let ComponentToRender;
  if (subdomain === "admin") {
    ComponentToRender = <AdminRouter />;
  } else if (subdomain === "account") {
    ComponentToRender = <AccountRouter />;
  } else {
    // Default to MainRouter if subdomain is empty or "www"
    ComponentToRender = <MainRouter />;
  }

  return (
    <>
      {ComponentToRender}
    </>
  );
}

export default DomainRouting;

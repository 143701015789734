import React, { useContext, useEffect } from "react";
import { createContext, useState } from "react";
import axios from "axios";
import { SnackbarContext } from "./SnackbarContextProvider";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {

    const { showSnackbar } = useContext(SnackbarContext);

    const [user, setUser] = useState(null);

    const login = (email, password) => {
        axios.post(`${process.env.REACT_APP_BACKEND}/users/login`, {
            email,
            password
        }, {
            withCredentials: true
        }).then(res => {
            setUser(res.data);
            showSnackbar("Login successful", "success");
        }).catch(err => {
            console.log(err);
            showSnackbar("Login failed: " + err.response.data.err, "error");
        });
    }

    const logout = () => {
        axios.post(`${process.env.REACT_APP_BACKEND}/users/logout`, {}, {
            withCredentials: true
        }).then(res => {
            setUser(null);
            showSnackbar("Logout successful", "success");
        }).catch(err => {
            console.log(err);
            showSnackbar("Logout failed: " + err.response.data.err, "error");
        });
    }

    const autoLogin = () => {
        axios.get(`${process.env.REACT_APP_BACKEND}/users/auto-login`, {
            withCredentials: true
        }).then(res => {
            setUser(res.data);
            showSnackbar("Auto login successful", "success");
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        autoLogin();
    }, []);

    return (
        <AuthContext.Provider
            value={{
                user,
                login,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
import React from 'react'
import { SnackbarContextProvider } from './contexts/SnackbarContextProvider'
import DomainRouting from './Websites/DomainRouting'
import { AuthContextProvider } from './contexts/AuthContextProvider'

export default function App() {
  return (
    <SnackbarContextProvider>
      <AuthContextProvider>
        <DomainRouting />
      </AuthContextProvider>
    </SnackbarContextProvider>
  )
}

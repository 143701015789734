import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Box, Divider, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

const Login = () => {
  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string().min(6, 'Must be at least 6 characters').max(20, 'Must be 20 characters or less').required('Required')
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission here

      console.log(values);
    },
  });

  return (
    <Box textAlign={'center'} >
      <Typography variant='h4' component={'h1'}>Login</Typography>
      <Divider />
      <br />
      <form onSubmit={formik.handleSubmit}>
        <Grid2 container spacing={1}>
          <Grid2 xs={12}>
            <TextField
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              // fullWidth  
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid2>
          <Grid2 xs={12}>
            <TextField
              id="password"
              name="password"
              label="Password"
              type="password"
              variant="outlined"
              // fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid2>
          <Grid2 xs={12}>

            <Button type="submit" variant="contained" color="primary">
              Login
            </Button>
          </Grid2>

        </Grid2>
      </form>
    </Box>
  );
};

export default Login;
